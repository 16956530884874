<template>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title>
          <v-icon left>
            mdi-robot-happy
          </v-icon>

          CoachAssist
          <v-spacer />
          <v-btn icon @click="toggleDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-4">
          <div class="chat-container" ref="chatContainer"> 

            <!-- Mensaje inicial con instrucciones -->
            <div class="message assistant">
              <v-alert type="info" color="primary darken-2" border="left">
                <p>
                  Escribe un mensaje para comenzar la planificación.
                </p>

                <p><strong>Instrucciones: </strong></p>

                <ul>
                  <li>Escribe una descripción de tu planificación.</li>
                  <li>El asistente generará una lista de ejercicios con los que están presentes en el glosario.</li>
                  <li>Confirma la planificación si estás de acuerdo.</li>
                </ul>
              </v-alert>
            </div>
                
            <div
              v-for="(message, index) in messages"
              :key="index"
              :class="['message', message.sender]"
            >
              <div class="message-content">
                <p v-if="message.type === 'text'" class="grey pa-2 py-1 rounded-lg">{{ message.content }}</p>
                <div v-else-if="message.type === 'component'">
                  <v-card elevation="0">
                    <v-chip class="mb-2 rounded-lg">
                      Aquí tienes una lista de ejercicios
                    </v-chip>
                    <Simple :exercises="message.content" class="elevation-4" />

                    <v-card-actions>
                   
                      <v-spacer/>
                      <v-btn 
                        color="primary"
                        @click="confirmWorkout(message.content)"
                        class="mt-2"
                        :disabled="loading"
                      >
                        <v-icon left>mdi-check</v-icon>
                        Confirmar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
              </div>
            </div>

            <v-slide-x-transition hide-on-leave mode="in-out">
              <div class="message assistant" v-show="loading">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </div>
            </v-slide-x-transition>
          </div>

          <v-text-field
            v-model="userInput"
            placeholder="Escribe tu mensaje"
            @keyup.enter="sendMessage"
            :disabled="loading"
            filled
            rounded
            class="rounded-lg"
            hide-details=""
          >
            <template v-slot:append>
              <v-btn icon @click="sendMessage" :loading="loading" class="mx-auto mb-4">
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </template>
          </v-text-field>

          <div v-if="error">
            <v-alert type="error">{{ error }}</v-alert>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import { getFunctions, httpsCallable } from 'firebase/functions';
import Simple from '@/components/planifications/view/Simple.vue';

export default {
  components: {
    Simple,
  },
  data() {
    return {
      userInput: '',
      messages: [],
      loading: false,
      error: null,
      dialog: false,
    };
  },
  methods: {
    async sendMessage() {
      if (!this.userInput) {
        this.error = 'Por favor, escribe un mensaje.';
        return;
      }

      this.loading = true;
      this.error = null;

      // Agrega el mensaje del usuario
      this.messages.push({
        type: 'text',
        content: this.userInput,
        sender: 'user',
      });

      // Desplaza el chat hacia abajo
      this.$nextTick(() => {
        this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.scrollHeight;
      });

      try {
        // Prepara los últimos 10 mensajes para enviar
        const lastTenMessages = this.messages.map(message => {
          if (message.sender === 'user') {
            return {
              role: 'user',
              content: message.content,
              type: 'text',
            };
          } else if (message.sender === 'assistant' && message.type === 'component') {
            return {
              role: 'system',
              content: JSON.stringify(message.content),
                            type: 'text',

            };
          }
        });

        // Llama a la función cloud generateExerciseList con los últimos 10 mensajes
        const functions = getFunctions();
        const generateExerciseList = httpsCallable(functions, 'generateExerciseList');

        const response = await generateExerciseList({
          messages: lastTenMessages,
        });

        if (response.data) {
          // Agrega la respuesta del asistente
          this.messages.push({
            type: 'component',
            content: response.data,
            sender: 'assistant',
          });
        } else {
          throw new Error('Respuesta inválida del servidor');
        }
      } catch (err) {
        console.error('Error al generar la respuesta:', err);
        this.error = 'Error al generar la respuesta. Por favor, intenta nuevamente.';

        // Elimina el último mensaje del usuario
        this.messages.pop();

      } finally {
        this.loading = false;
        this.userInput = '';
      }
    },
    toggleDialog() {
      if (!this.dialog) {
        this.userInput = '';
        this.messages = [];
      }

      this.dialog = !this.dialog;
    },
    confirmWorkout(exercises) {
      this.$emit('confirmWorkout', exercises);
      this.$notify({
        title: 'Planificación generada',
        type: 'success',
      });
      this.toggleDialog();
    },
    async regen() {
      // similar a sendMessage pero sin enviar el mensaje del usuario

      this.loading = true;
      this.error = null;


      try {
        const lastTenMessages = this.messages.map(message => {
          if (message.sender === 'user') {
            return {
              role: 'user',
              content: message.content,
                            type: 'text',

            };
          } else if (message.sender === 'assistant' && message.type === 'component') {
            return {
              role: 'system',
              content: JSON.stringify(message.content),
                            type: 'text',

            };
          }
        });

        const functions = getFunctions();
        const generateExerciseList = httpsCallable(functions, 'generateExerciseList');

        const response = await generateExerciseList({
          messages: lastTenMessages,
        });

        if (response.data) {
          this.messages.pop();
          this.messages.push({
            type: 'component',
            content: response.data,
            sender: 'assistant',
          });
        } else {
          throw new Error('Respuesta inválida del servidor');
        }
      } catch (err) {
        console.error('Error al generar la respuesta:', err);
        this.error = 'Error al generar la respuesta. Por favor, intenta nuevamente.';
      } finally {
        this.loading = false;
      }



    },
  },
};
</script>

<style scoped>
.chat-container {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 16px;
}

.message {
  display: flex;
  margin-bottom: 8px;
}

.message.user {
  justify-content: flex-end;
}

.message.assistant {
  justify-content: flex-start;
}

.message-content {
  max-width: 70%;
  padding: 10px;
  border-radius: 10px;
}

.lds-ellipsis {
  /* Cambia el color aquí */
  color: var(--v-secondary);
}
.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
